import * as constants from './constants';
import config from '../../../config';

let initialState = {
  open: false,
  hide: false,
  id: '',
  user: null,
  url: '',
};

const isLocalStorage = typeof localStorage !== 'undefined';
let newState = {};
//lets load user data for reload
if (isLocalStorage && localStorage.admin) {
  initialState = JSON.parse(localStorage.admin);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_CURRENT_USER:
      return {
        ...state,
        user: action.id,
      };
    case constants.OPEN_ADMIN:
      return {
        ...state,
        open: !state.open,
        hide: !state.hide,
        url: `${config.apiURL}/wp-admin/post.php?post=${
          action.payload.id
        }&action=edit&iframe=true`,
        id: action.payload.id,
      };
    case constants.TOGGLE_ADMIN:
      return {
        ...state,
        hide: !state.hide,
      };
    default:
      return state;
  }
};
