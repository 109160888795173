import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//Admin requirements
import Modal from 'react-responsive-modal';
import Iframe from 'react-iframe';

import { toggleAdmin, openAdmin } from '../../containers/Admin/actions';
import './styles.css';

class Admin extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const contentId = this.props.content ? this.props.content.id : null;

    if (!this.props.user) {
      return null;
    }

    return (
      <div className={'admin-sidebar'}>
        <div
          className={`admin-sidebar-icon ${
            this.props.hide ? 'open' : 'closed'
          }`}
          onClick={() =>
            this.props.adminUrl === ''
              ? this.props.openAdmin(contentId)
              : this.props.toggleAdmin()
          }
        >
          <span />
        </div>
        <Modal
          open={this.props.open}
          onClose={this.props.toggleAdmin}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          showCloseIcon={false}
          blockScroll={false}
          focusTrapped={false}
          classNames={{
            modal: `admin-modal ${!this.props.hide ? 'hide' : 'show'}`,
            overlay: `admin-overlay ${!this.props.hide ? 'hide' : 'show'}`,
          }}
          center
        >
          {typeof this.props.adminUrl == 'string' && (
            <Iframe
              url={this.props.adminUrl}
              width={'100%'}
              height={'100%'}
              className="admin-content"
              display="initial"
              position="relative"
              allowFullScreen
            />
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  open: state.admin.open,
  hide: state.admin.hide,
  user: state.admin.user,
  adminUrl: state.admin.url,
  content: state.content.currentContent,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ toggleAdmin, openAdmin }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin);
