import config from '../../../config';
import * as constants from './constants';
import * as AdminConstants from '../Admin/constants';
import * as OptionsConstants from '../Options/constants';
import * as MenusConstants from '../Menus/constants';
import { http } from '../../utils/OfflineAxios';

export const getSystemInit = (lang = config.defaultLang) => async dispatch => {
  try {
    dispatch({
      type: constants.GET_INIT_REQUEST,
    });
    const system = await http.get(
      `${config.apiURL}/wp-json/system/v1/init?lang=${lang}`,
      {}
    );

    dispatch({
      type: constants.GET_INIT_SUCCESS,
    });

    if (system.data.user) {
      dispatch({
        type: AdminConstants.SET_CURRENT_USER,
        id: system.data.user,
      });
    }

    if (system.data.options) {
      dispatch({
        type: OptionsConstants.SET_CURRENT_OPTIONS,
        options: system.data.options,
      });
    }

    if (
      system.data['menu-header'] &&
      system.data['menu-header'] &&
      system.data['menu-shop']
    ) {
      dispatch({
        type: MenusConstants.SET_CURRENT_MENUS,
        menus: {
          topmenu: system.data['menu-header'],
          submenu: system.data['menu-sub'],
          shopmenu: system.data['menu-shop'],
        },
      });
    }
  } catch (error) {
    console.log(error);
  }
};
