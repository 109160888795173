// The basics
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { frontloadConnect } from "react-frontload";
import LoadingBar from "react-redux-loading-bar";

// Action creators and helpers
// import { establishCurrentUser } from '../modules/auth';
import { getSystemInit } from "./containers/System/actions";
import { getMenus } from "./containers/Menus/actions";
import { getOptions } from "./containers/Options/actions";
import { isServer } from "../store";
import { setLanguage } from "./containers/Language/actions";

// import Header from './header';

import GDPR from "./components/GDPR";
import Admin from "./components/Admin";
import Routes from "./routes";

import "normalize.css/normalize.css";
import "animate.css/animate.min.css";
import "simple-line-icons/css/simple-line-icons.css";
import "bootstrap/dist/css/bootstrap.css";
import "slick-carousel/slick/slick.css";

import "./app.css";

const frontload = async (props) => {
  return Promise.all([
    props.setLanguage(props.match.params.lang),
    props.getSystemInit(props.match.params.lang),
  ]);
};

class App extends Component {
  constructor(props) {
    super(props);

    if (typeof window !== "undefined") {
      var browserUpdate = require("browser-update");

      browserUpdate({
        required: {
          e: -2,
          i: 11,
          f: -3,
          o: -3,
          s: 10.1,
          c: "64.0.3282.16817",
          samsung: 7.0,
          vivaldi: 1.2,
        },
        insecure: true,
      });
    }
  }
  componentWillMount() {
    // this.props.getMenus();
    // if (!isServer) {
    //   this.props.establishCurrentUser();
    // }
  }

  render() {
    // if (!this.props.content || this.props.content.length === 0) {
    //   return (
    //     <div className={'loading'}>
    //       <div className={'logo-wrap'}>
    //         <SVG src={animated} />
    //       </div>
    //     </div>
    //   );
    // }

    return (
      <div id="app">
        <LoadingBar
          style={{
            zIndex: 999,
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "#fff",
          }}
        />

        <Routes />

        <Admin />
        <GDPR content={this.props.gdrp} />
        <a
          style={{
            position: "absolute",
            zIndex: 99,
            bottom: "1em",
            right: "2em",
          }}
          href="https://www.livroreclamacoes.pt/"
          target="_blank"
        >
          <img src="/reclama.png" height="60px" />
        </a>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  gdrp: state.options.gdrp,
  status: state.content.status,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getMenus, getOptions, setLanguage, getSystemInit },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    frontloadConnect(frontload, {
      onMount: true,
      onUpdate: false,
    })(App)
  )
);
