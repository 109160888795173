import axios from 'axios';
import config from '../../../config';
import * as constants from './constants';
import { http } from '../../utils/OfflineAxios';

export const getMenus = (lang = config.defaultLang) => async dispatch => {
  try {
    dispatch({
      type: constants.SET_CURRENT_MENUS_REQUEST,
    });

    const allMenus = [
      await http.get(
        `${config.apiURL}/wp-json/menus/v1/menus/header-menu?lang=${lang}`,
        {}
      ),
    ];

    const constmenuseContent = await Promise.all(allMenus);

    dispatch({
      type: constants.SET_CURRENT_MENUS,
      menus: {
        topmenu: constmenuseContent[0].data,
      },
    });
  } catch (error) {
    console.log(error);
  }
};
